import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-equipment-mng';

class StockEquipmentMng {

    /** 장비 수리내역 조회 */
    async getEqpRepairHdr(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/list` ,{params} );
        return data;
    }
    /** 장비 수리내역 조회 VO */
    async getEqpRepairHdrVO(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/listVO` ,{params} );
        return data;
    }
    /** 장비 수리내역 상세 조회 */
    async getEqpRepairDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/Detail` ,{params} );
        return data;
    }
    /** 장비 수리내역 상세 등록 */
    async postEqpRepairDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/repair/Detail` ,params);
        return data;
    }
    /** 장비 수리내역 상세 수정 */
    async patchEqpRepairDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/repair/Detail` ,params);
        return data;
    }
    /** 장비 수리내역 상세 삭제 */
    async deleteEqpRepairDetail(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/repair/Detail` ,{params} );
        return data;
    }
    /** 장비 출고 품목 조회 */
    async getEqpRepairDtlList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/DtlList` ,{params} );
        return data;
    }


    /** 오일 사용량 목록 조회 */
    async getOilUseDayList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/oil/DayList`, {params});
        return data;
    }
    /** 오일 사용량 일별 품목 조회 */
    async getOilUseList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/oil/oilUseList`, {params});
        return data;
    }
    /** 오일 사용량 저장 */
    async saveOilUseList(params) {
        const {data} = await  GolfErpAPI.http.put(`${ROOT_PATH}/oil/oilUseList` ,params);
        return data;
    }

    
    /** 장비출역등록  */
    async getEqpDlivyList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/eqpDlivyList`, {params});
        return data;
    }
    /** 장비출역등록 상세 조회 */
    async getEqpDlivyDetail(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/eqpDlivyDetail`, {params});
        return data;
    }
    /** 장비출역등록 품목 조회 */
    async getEqpDlivySupplieseList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/eqpDlivySupplieseList`, {params});
        return data;
    }
    /** 장비출역등록 작업지역 조회 */
    async getEqpDlivyAreaList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/eqpDlivyAreaList`, {params});
        return data;
    }
    /** 장비출역등록 근무자 조회 */
    async getEqpDlivyWrkrList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/eqpDlivyWrkrList`, {params});
        return data;
    }
    /** 장비출역등록 상세 등록 */
    async postEqpDlivyDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/dlivy/Detail` ,params);
        return data;
    }
    /** 장비출역등록 상세 수정 */
    async patchEqpDlivyDetail(params) {
        const {data} = await  GolfErpAPI.http.patch(`${ROOT_PATH}/dlivy/Detail` ,params);
        return data;
    }
    /** 장비출역등록 상세 삭제 */
    async deleteEqpDlivyDetail(params) {
        const {data} = await  GolfErpAPI.http.delete(`${ROOT_PATH}/dlivy/Detail` ,{params});
        return data;
    }
}

export default new StockEquipmentMng();